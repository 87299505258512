<script>
import { inject } from "vue";
import IconMenu from "./Icons/IconMenu.vue";
import MobileMenuItem from "./MobileMenuItem.vue";
export default {
  components: { IconMenu, MobileMenuItem },
  name: "MobileMenu",
  data() {
    return {
      menuItems: [
        {
          title: "Спорт",
          type: "sport",
          links: [
            {
              id: 235,
              name: "Плавание",
            },
            {
              id: 246,
              name: "Футбол на открытом поле",
            },
            {
              id: 249,
              name: "Командные игры в зале",
            },
            {
              id: 251,
              name: "Единоборства",
            },
            {
              id: 253,
              name: "Легкая атлетика",
            },
            {
              id: 255,
              name: "Пулевая стрельба",
            },
            {
              id: 257,
              name: "Стрельба из лука",
            },
            {
              id: 259,
              name: "Секции",
            },
            {
              id: 261,
              name: "Зимние виды спорта",
            },
            {
              id: 263,
              name: "Соревнования и чемпионаты",
            },
            {
              id: 292,
              name: "Тренажерные залы «АТЛЕТ»",
            },
            {
              id: 294,
              name: "Конный спорт",
            },
          ],
        },
        {
          title: "Отдых",
          type: "vacation",
          links: [
            {
              id: 327,
              name: "Сауны",
            },
            {
              id: 329,
              name: "СПА-центр ФСК",
            },
            {
              id: 331,
              name: "Отели",
            },
            {
              id: 357,
              name: "DSS_STUDIO",
            },
            {
              id: 365,
              name: "Культурно-массовые мероприятия",
            },
          ],
        },
        {
          title: "Дирекция",
          type: "management",
          links: [
            {
              id: 337,
              name: "Руководство",
            },
            {
              id: 339,
              name: "События",
            },
            {
              id: 341,
              name: "Фото",
            },
            {
              id: 343,
              name: "Документы",
            },
            {
              id: 345,
              name: "Закупки",
            },
            {
              id: 347,
              name: "Вакансии",
            },
            {
              id: 349,
              name: "Партнеры",
            },
            {
              id: 351,
              name: "Важные ссылки",
            },
            {
              id: 355,
              name: "Контакты",
            },
          ],
        },
      ],
    };
  },
  setup() {
    const isMobileMenu = inject("isMobileMenu");
    const toggleMobileMenu = inject("toggleMobileMenu");

    return { isMobileMenu, toggleMobileMenu };
  },
};
</script>
<template>
  <div class="mobile-menu" @click="toggleMobileMenu">
    <icon-menu width="36px" />
    <teleport to="body">
      <div v-if="isMobileMenu" class="modal">
        <div class="modal__body">
          <mobile-menu-item
            v-for="(menu, n) in menuItems"
            :key="n"
            :menu="menu"
            :index="n"
          />
          <button class="btn btn-close" @click="toggleMobileMenu">x</button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<style lang="sass" scoped>
.modal
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  overflow-x: hidden
  &__body
    position: relative
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    background-color: white
    padding-top: 90px
    overflow-y: scroll
  & .btn-close
    position: absolute
    top: 20px
    right: 15px
    padding: 10px 15px
</style>
