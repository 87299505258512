<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { onMounted, provide, ref } from "vue";
import { useStore } from "vuex";
import { useFetch } from "@vueuse/core";
// import Popup from "./components/Popup.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    // Popup,
  },
  setup() {
    const isMobileMenu = ref(false);
    const store = useStore();

    const toggleMobileMenu = () => {
      isMobileMenu.value = !isMobileMenu.value;
      if (isMobileMenu.value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    function fetchSlides() {
      let url = "https://admin.dss-sport.ru/wp-json/wp/v2/slides?_embed";
      const { data } = useFetch(url).get().json();
      store.commit("SET_SLIDES", {
        slides: data,
      });
      // return data;
    }
    onMounted(() => {
      fetchSlides();
    });

    provide("isMobileMenu", isMobileMenu);
    provide("toggleMobileMenu", toggleMobileMenu);
  },
};
</script>

<template>
  <Header />
  <main>
    <AppLayout>
      <router-view />
    </AppLayout>
  </main>
  <Footer />
  <!-- <Popup /> -->
</template>

<style lang="scss"></style>
