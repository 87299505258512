import { createStore } from "vuex";

export default createStore({
  state: {
    searchStr: "",
    searchResult: [],
    sideBarMenu: [],
    isNewsSideBar: false,
    newsList: [],
    newsData: {},
    itemData: {},
    slides: [],
  },
  mutations: {
    TOGGLE_NEWS_SIDER_BAR(state) {
      state.isNewsSideBar = !state.isNewsSideBar;
    },
    OPEN_NEWS_SIDE_BAR(state) {
      state.isNewsSideBar = true;
    },
    CLOSE_NEWS_SIDER_BAR(state) {
      state.isNewsSideBar = false;
    },
    SET_ITEM_DATA(state, payload) {
      state.itemData = payload.itemData;
    },
    SET_SIDE_BAR_MENU(state, payload) {
      state.sideBarMenu = payload.sideBarMenu;
    },
    SET_NEWS_LIST_DATA(state, payload) {
      state.newsList = payload.newsList;
    },
    SET_NEWS_ITEM_DATA(state, payload) {
      state.newsData = payload.newsData;
    },
    CLEAR_NEWS_ITEM_DATA(state) {
      state.newsData = {};
    },
    SET_SEARCH_STR(state, payload) {
      state.searchStr = payload.searchStr;
    },
    SET_SEARCH_RESULT(state, payload) {
      state.searchResult = payload.searchResult;
    },
    SET_SLIDES(state, payload) {
      state.slides = payload.slides;
    },
  },
  actions: {},
  getters: {
    isShowNews(state) {
      return state.isNewsSideBar;
    },
    getItemData(state) {
      return state.itemData;
    },
    getSideBarMenu(state) {
      return state.sideBarMenu;
    },
    getNewsList(state) {
      return state.newsList;
    },
    getNewsListLen(state) {
      return state.newsList?.length;
    },
    getNewsItem(state) {
      return state.newsData;
    },
    getSearchStr(state) {
      return state.searchStr;
    },
    getSearchResult(state) {
      return state.searchResult;
    },
    getSearchResultLen(state) {
      return state.searchResult?.length;
    },
    getSlides(state) {
      return state.slides;
    },
  },
  modules: {},
});
