<script>
export default {
  props: {
    newsItem: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup() {},
};
</script>
<template>
  <div class="news-drawer-block">
    <h5>{{ newsItem?.heading }}</h5>
    <p>{{ newsItem?.formatted_date }}</p>
    <img
      class="news-drawer-block--image"
      v-if="newsItem?._embedded?.['wp:featuredmedia']"
      :src="newsItem?._embedded?.['wp:featuredmedia']?.['0']?.source_url"
      alt=""
    />
    <div v-html="newsItem?.content?.rendered"></div>
    <div v-if="newsItem?.meta_tags">
      <span v-for="tag in newsItem?.meta_tags" :key="tag?.term_id">{{
        tag?.name
      }}</span>
    </div>
    <p></p>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"
.news-drawer-block
  & h5
    text-transform: uppercase
    font-size: $base-font-size*1.5
    font-weight: 800
    letter-spacing: 0.05em
  & img
    width: 100%
    height: auto
    object-fit: fill
    margin-bottom: $default-padding
  & p, a, span
    width: 100%
    max-width: 100%
    word-break: break-word
  &--image
</style>
