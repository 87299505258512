<script>
export default {
  name: "icon-news",
  props: {
    iconColor: {
      type: String,
      default: "#027FC7",
    },
  },
  setup() {},
};
</script>
<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8" y="9" width="20" height="3" rx="1.5" :fill="iconColor" />
    <rect x="8" y="16" width="20" height="3" rx="1.5" :fill="iconColor" />
    <rect x="8" y="23" width="20" height="3" rx="1.5" :fill="iconColor" />
  </svg>
</template>
