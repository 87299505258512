<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useFetch, useMediaQuery } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import NewsItem from "./NewsItem.vue";
import IconArrowLeft from "./Icons/IconArrowLeft.vue";
export default {
  components: { NewsItem, IconArrowLeft },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isShowNews = computed(() => store.getters.isShowNews);
    const getNewsList = computed(() => store.getters.getNewsList);
    const getNewsListLen = computed(() => store.getters.getNewsListLen);
    const getNewsItem = computed(() => store.getters.getNewsItem);
    const isLoading = ref(true);
    const perPage = ref(10);
    const isLoadMore = ref(true);
    const psScrollRef = ref(null);

    const isLargeScreen = useMediaQuery("(min-width: 1700px)");

    function openSideBarOnPageScreen() {
      store.commit("OPEN_NEWS_SIDE_BAR");
    }

    function closeSideBar() {
      store.commit("CLOSE_NEWS_SIDER_BAR");
    }

    function scrollTop() {
      psScrollRef.value.$el.scrollTop = 0;
    }

    async function scrollBot() {
      const elHeight = await psScrollRef.value.$el.scrollHeight;
      new Promise(() => {
        setTimeout(() => {
          psScrollRef.value.$el.scrollTop = elHeight * 4;
        }, 2000);
      });
    }

    function setNewsItem(item) {
      store.commit("SET_NEWS_ITEM_DATA", {
        newsData: item,
      });
      router.push({ query: { news: item.id } });
      scrollTop();
    }

    function clearNewsItem() {
      store.commit("CLEAR_NEWS_ITEM_DATA");
      router.push(route.path);
    }

    function fetchNewsList() {
      isLoading.value = true;
      const { data, isFinished } = useFetch(
        `https://admin.dss-sport.ru/wp-json/wp/v2/posts?per_page=${perPage.value}&_embed`,
        { refetch: true }
      )
        .get()
        .json();
      store.commit("SET_NEWS_LIST_DATA", {
        newsList: data,
      });
      isLoading.value = isFinished;
      scrollTop();
    }

    const loadMore = () => {
      if (!(perPage.value > getNewsListLen.value)) {
        perPage.value += 5;
        fetchNewsList();
        scrollBot();
      }
      if (perPage.value > getNewsListLen.value) isLoadMore.value = false;
    };

    onMounted(() => {
      fetchNewsList();
    });

    watch(
      () => route.path,
      async () => {
        try {
          if (
            (route.name === "Page" ||
              route.name === "Reception" ||
              route.name === "Search") &&
            isLargeScreen.value
          ) {
            openSideBarOnPageScreen();
          } else {
            closeSideBar();
          }
        } catch (e) {
          console.error(e);
        }
      }
    );

    return {
      isShowNews,
      getNewsList,
      getNewsItem,
      setNewsItem,
      clearNewsItem,
      isLoading,
      isLoadMore,
      loadMore,
      psScrollRef,
      filter: (v) => computed(() => "#" + v),
    };
  },
};
</script>
<template>
  <div
    class="news-drawer"
    :class="{ isShow: isShowNews, isExpand: getNewsItem?.id }"
  >
    <perfect-scrollbar ref="psScrollRef">
      <div class="news-drawer__wrapper">
        <div class="news-drawer__head">
          <h5 v-if="getNewsItem?.id === undefined">новости</h5>
          <h5
            @click="clearNewsItem()"
            v-else-if="getNewsItem?.id != undefined"
            class="arrow_back"
          >
            <icon-arrow-left />
          </h5>
        </div>
        <ul class="news-drawer__list" v-show="getNewsItem?.id === undefined">
          <a v-for="item in getNewsList" :key="item.id" style="cursor: pointer">
            <li class="news-drawer__item" @click="setNewsItem(item)">
              <span class="date">{{ item?.formatted_date }}</span>
              <!-- <h5 class="title">
                {{ item?.heading }}
              </h5> -->
              <h5 class="title" v-html="item?.title.rendered"></h5>
              <img
                v-if="item?._embedded?.['wp:featuredmedia']"
                :src="item?._embedded?.['wp:featuredmedia']?.['0']?.source_url"
                alt=""
                class="thumb-news"
              />
              <p
                v-show="item?.acf?.show_excerpt"
                v-html="item?.short_desc"
                class="extract"
              ></p>
              <div class="tag_list" v-if="item?.meta_tags">
                <span
                  v-for="tag in item?.meta_tags"
                  :key="tag?.term_id"
                  class="tags"
                >
                  {{ filter(tag?.name) }}</span
                >
              </div>
            </li>
          </a>
        </ul>
        <div class="news-drawer__more" v-show="getNewsItem?.id === undefined">
          <button class="btn" @click="loadMore" v-if="isLoadMore">
            загрузить ещё
          </button>
        </div>
        <news-item
          v-if="getNewsItem?.id != undefined"
          :newsItem="getNewsItem"
        />
      </div>
    </perfect-scrollbar>
  </div>
</template>
<style lang="sass">
@import "../styles/scss/_mixins.scss"

.ps
  height: 100%
  @media only screen and (max-width: $sizes-sm)
    max-height: calc(100vh - 90px)

.news-drawer
  // height: calc(100vh - 206px)
  width: $default-padding*27.5
  background: #fafafa
  position: absolute
  top: 0
  bottom: 0
  right: 0
  transform: translateX(120%)
  transition: transform .2s ease-in-out, width .1s ease-in
  overflow-y: hidden
  @media only screen and (max-width: $sizes-sm)
    width: 100%
  &.isShow
    transform: translateX(0)
    &.isExpand
      // width: $default-padding*42.5
      width: 50%
      @media only screen and (max-width: $sizes-sm)
        max-width: 100%
        width: 100%
    & .ps
      @media only screen and (max-width: $sizes-sm)
        overflow-y: scroll !important
  &__wrapper
    position: relative
    padding: 0 $default-padding*1.95
    @include d-flex(column, flex-start, flex-start, null, null, 10px)
  &__head
    width: 100%
    padding: $default-padding*1.95 0 $default-padding*.7
    position: sticky
    top: 0
    background: #fafafa
    z-index: 2
    & h5
      text-transform: uppercase
      font-size: $base-font-size*2
      font-weight: 800
      letter-spacing: 0.05em
    & .arrow_back svg
      transform: scale(1.5)
      cursor: pointer
  &__list
    list-style: none
    padding: 0
    // margin: 0
  &__item
    position: relative
    @include d-flex(column, flex-start, flex-start, null, null, 10px)
    margin-bottom: $default-padding*2.1
    &:hover
      & .extract
        opacity: .85
    & .date
      font-size: $base-font-size*.8
      display: inline-flex
    & .title
      font-size: $base-font-size
      font-weight: 600
      line-height: $base-font-size*1.2
    & .thumb-news
      width: 100%
      height: auto
      object-fit: contain
    & .extract
      font-size: $base-font-size*.85
      line-height: $base-font-size*1.3
      color: $text-color
      margin: $default-padding*.2 0
      transition: opacity .2s ease-in-out
    & .tag_list
      @include d-flex(row, flex-start, flex-start, null, null, 6px)
    & .tags
      font-size: $base-font-size*.8
      font-weight: 300
      color: $primary-color
  &__more
    @include d-flex(row, center, center, null, null, null)
    width: 100%
</style>
